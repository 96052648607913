export const languages = {
    ru:{
        timeRocketStart:"Время запуска ракеты:",
        menu:"МЕНЮ",
        orel:"ОРЕЛ",
        reshka:"РЕШКА",
        timeStartMines: "СЛЕДУЮЩИЙ СИГНАЛ ЧЕРЕЗ:",
        minutes:"минуты",
        seconds:"секунды",
        hash_of_the_round:"Введите хеш рауда",
        left_skulls:'ЛЕВЫЙ ЧЕРЕП',
        right_skulls:'ПРАВЫЙ ЧЕРЕП',
        center_skulls:'ЦЕНТРАЛЬНЫЙ ЧЕРЕП',
        hash:'Введите хэш раунда',
        step:'ШАГ',
    },
    en:{
        timeRocketStart:"Time of starting rocket:",
        menu:"MENU",
        orel:"HEADS",
        reshka:"TAILS",
        timeStartMines: "NEXT SIGNAL IN:",
        minutes:"minutes",
        seconds:"seconds",
        hash_of_the_round:"Enter the hash of the round",
        left_skulls:'LEFT SKULLS',
        right_skulls:'RIGHT SKULLS',
        center_skulls:'CENTER SKULLS',
        hash:'Enter round hash',
        step:'STEP',
    },
    spain:{
        timeRocketStart:"Hora de lanzamiento del cohete:",
        menu:"MENÚ",
        orel:"ÁGUILA",
        reshka:"CRUZ",
        timeStartMines: "SEGUIR LA SEÑAL POR:",
        minutes:"minutos",
        seconds:"segundos",
        hash_of_the_round:"Introduce el hash de la ronda.",
        left_skulls:'CRÁNEO IZQUIERDO',
        right_skulls:'CRÁNEO DERECHO',
        center_skulls:'CRÁNEO CENTRAL',
        hash:'Ingrese hash redondo',
        step:'PASO',
    }
}

